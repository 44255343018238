import axios from 'axios'

const apiClient = axios.create({
    //baseURL: 'https://www.edinaut.com/apizachtrans',
    //baseURL: 'http://92.240.254.133:8000'
    baseURL: process.env.VUE_APP_API_URL,
    //headers: {
    //    Accept: 'application/json',
    //    'content-type': 'application/json'
    //}
})

export default {
    callLogin(credentials) {
        return apiClient.post('/login', credentials)
    },
    callRegister(credentials) {
        return apiClient.post('/register', credentials)
    },
    setAuthorizationHeader(bearer) {
        apiClient.defaults.headers.common['Authorization'] = bearer
    },
    callUnloading(par) {
        return apiClient.get('/unloading', par)
    },
    callRegistrationNo(par) {
        return apiClient.get('/registration_no', par)
    },
    callReverse(par) {
        return apiClient.get('/reverse', par)
    },
    callUsers(par) {
        return apiClient.get('/users', par)
    },
    callUsersRoles(par) {
        return apiClient.get('/users_roles', par)
    },
    callUpdateUsers(dat) {
        return apiClient.put('/users', dat)
    },
    callInsertUsers(data) {
        return apiClient.post('/users', data)
    },
    callDeleteUsers(data) {
        return apiClient.delete('/users', data)
    },
    callLog(data) {
        return apiClient.post('/log', data)
    },
    callEvents(par) {
        return apiClient.get('/events', par)
    },
    callUpdateEvents(dat) {
        return apiClient.put('/events', dat)
    },
    callInsertReg(data) {
        return apiClient.post('/regevent', data)
    },
    callTerms(par) {
        return apiClient.get('regeventterms', par)
    },
}
