<template>
    <v-card
        class="mx-auto cursor-pointer"
        max-width="344"
        :color="isSelected ? 'orange' : 'white'"
        @click="handleClick"
        :class="{ 'disabled-card': isDisabled }"
    >
        <v-card-text>
            <div>{{ term.event_name }}</div>
            <p class="text-h4 text--primary">{{ term.term }}</p>
            <p>Kapacita: {{ term.term_capacity }}</p>
            <p>Přihlášeno: {{ term.reg_count }}</p>
            <p>Zbývá: {{ term.reg_remains }}</p>
            <!--<div class="text--primary">
                well meaning and kindly.<br />
                "a benevolent smile"
            </div>-->
        </v-card-text>
        <!--<v-card-actions>
            <v-btn :color="isSelected ? 'orange' : 'white'" @click="handleClick"> Vybrat</v-btn>
        </v-card-actions>-->
    </v-card>
</template>

<script>
export default {
    name: 'TermComponent',
    props: {
        term: Object,
        selectedTerm: Object,
    },
    computed: {
        isSelected() {
            return this.selectedTerm && this.selectedTerm.term_id === this.term.term_id && this.term.reg_remains > 0
        },
        isDisabled() {
            return this.term.reg_remains === 0
        },
    },
    methods: {
        handleClick() {
            if (!this.isDisabled) {
                this.$emit('select-term', this.term)
            }
        },
    },
}
</script>

<style>
.cursor-pointer {
    cursor: pointer;
}
.disabled-card {
    pointer-events: none;
    opacity: 0.6;
}
</style>
