import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
//import MySqlServices from './services/MySqlServices'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    created() {
        //MySqlServices.setAuthorizationHeader(`Bearer ${store.state.token}`)
    },
    render: (h) => h(App),
}).$mount('#app')
