<template>
    <v-app>
        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'App',

    data: () => ({
        API_URL: process.env.VUE_APP_API_URL,
    }),
}
</script>
