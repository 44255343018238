<template>
    <v-container>
        <FormComponent />
    </v-container>
</template>

<script>
import FormComponent from '../components/FormComponent.vue'

export default {
    name: 'Home',
    components: {
        FormComponent,
    },
}
</script>
