<template>
    <v-form @submit.prevent="submitForm" v-model="formValidity" ref="form">
        <v-text-field label="Jméno" v-model="formData.reg_name_first" :rules="requiredRule" required :maxlength="20"></v-text-field>
        <v-text-field label="Příjmení" v-model="formData.reg_name_last" :rules="requiredRule" required :maxlength="20"></v-text-field>
        <v-text-field label="Email" v-model="formData.reg_email" :rules="emailRules" required :maxlength="40"></v-text-field>
        <v-text-field label="Telefon" v-model="formData.reg_tel" :rules="phoneRules" required :maxlength="20"></v-text-field>
        <!--<v-textarea label="Poznámka" v-model="formData.reg_remark"></v-textarea>-->

        <!--<v-subheader class="subtitle-1 pl-0">Vyberte termín:</v-subheader>-->
        <v-subheader :class="{ 'red--text': !formData.term, 'black--text': formData.term }" class="subtitle-1 pl-0"
            >Vyberte termín:</v-subheader
        >

        <v-row>
            <v-col v-for="term in terms" :key="term.id" cols="auto">
                <TermComponent :term="term" @select-term="selectTerm" :selected-term="formData.term" />
            </v-col>
        </v-row>
        <v-divider class="my-4"></v-divider>
        <v-btn type="submit" color="primary" :disabled="!formValidity || !formData.term">Odeslat</v-btn>
        <v-snackbar v-model="snackbar" :timeout="snackbarTimeout">
            {{ snackbarMessage }}
            <v-btn text @click="snackbar = false">Zavřít</v-btn>
        </v-snackbar>
    </v-form>
</template>

<script>
//import axios from 'axios'
import TermComponent from './TermComponent.vue'
import MySqlServices from '../services/MySqlServices'

export default {
    name: 'FormComponent',
    components: {
        TermComponent,
    },
    data() {
        return {
            formData: {
                reg_name_first: '',
                reg_name_last: '',
                reg_email: '',
                reg_tel: '',
                reg_remark: '',
                term: null,
                term_id: 0,
            },
            terms: [],
            requiredRule: [(v) => !!v || 'Povinné pole'],
            emailRules: [(v) => !!v || 'Email je povinný', (v) => /.+@.+\..+/.test(v) || 'E-mail musí být ve správném formátu'],
            phoneRules: [
                (v) => !!v || 'Telefon je povinný',
                (v) => /^\d+$/.test(v.replace(/\s/g, '')) || 'Telefon musí obsahovat pouze číslice',
                (v) => (v && v.replace(/\s/g, '').length >= 9) || 'Telefon musí mít minimálně 9 číslic',
            ],
            termsRule: [(v) => !!v || 'Vyberte prosím termín'],
            formValidity: false,
            snackbar: false,
            snackbarTimeout: 10000, // Timeout pro zobrazení Snackbar (v ms)
            snackbarMessage: '', // Zpráva pro zobrazení v Snackbar
        }
    },
    created() {
        this.loadTerms()
    },
    methods: {
        loadTerms() {
            const query = this.$route.query // Získání hodnoty 'q' z query parametru
            if (query) {
                MySqlServices.callTerms({ params: query })
                    .then(({ data }) => {
                        this.terms = data // Načtení výsledků do shipments
                        //this.isLoading = false
                        //const actualLeg = this.getActualLeg()
                        //console.log(actualLeg)
                        //this.populateTimelineItems(actualLeg)
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                        //this.isLoading = false // Set loading flag to false in case of an error
                    })
            } else {
                // Pokud není definován query parametr 'q', můžete zde udělat něco jiného nebo zobrazit chybové hlášení
                //this.isLoading = false
            }
        },
        selectTerm(term) {
            this.formData.term = term
            this.formData.term_id = term.term_id
        },
        submitForm() {
            MySqlServices.callInsertReg({ data: this.formData }).then(({ data }) => {
                if (data.result == 'ok') {
                    this.snackbarMessage =
                        'Registrace byla úspěšná, zkontrolujte svou email schránku, popřípadě složku spam pro potvrzení registrace.'
                    this.snackbar = true
                    this.resetForm()
                    this.loadTerms()
                } else if (data.result == 'okbutemail') {
                    this.snackbarMessage = 'Registrace byla úspěšná, ale nedošlo k odeslání emailu.'
                    this.snackbar = true
                    this.resetForm()
                    this.loadTerms()
                } else if (data.result == 'error') {
                    this.snackbarMessage = 'Registrace se nezdařila. ' + data.value
                    this.snackbar = true
                }
            })
        },
        resetForm() {
            this.formData.reg_name_first = ''
            this.formData.reg_name_last = ''
            this.formData.reg_email = ''
            this.formData.reg_tel = ''
            this.formData.reg_remark = ''
            this.formData.term = null
            this.formData.term_id = 0
            this.formValidity = false
            this.$refs.form.resetValidation() // Resetovat validaci formuláře
        },
    },
}
</script>
